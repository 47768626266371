export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  experience: {
    open: false,
    expand: false,
  },
  checkout: {
    open: false,
    expand: false,
  },
})

export const mutations = {
  TOGGLE_NAVBAR: (state, { type, open }) => {
    if (state[type]) {
      state[type].open = open || !state[type].open
    }
  },
  EXPAND_CONTENT: (state, { type, expand }) => {
    if (state[type]) {
      state[type].expand = expand || !state[type].expand
    }
  },
  RESET_NAVBAR: (state, type) => {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(state[type])) {
      state[type][key] = false
    }
  },
}

export const getters = {}

export const actions = {
  toggle({ commit }, data) {
    commit('TOGGLE_NAVBAR', data)
  },
  expand({ commit }, data) {
    commit('EXPAND_CONTENT', data)
  },
  reset({ commit }) {
    const types = ['experience', 'checkout']
    types.forEach((type) => commit('RESET_NAVBAR', type))
  },
}
