/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default async (req, res, next) => {
  const userCountry =
    req.headers['CF-IPCountry'] ||
    req.headers['cf-ipcountry'] ||
    req.headers['Cf-Ipcountry'] ||
    ''

  const groups = await req.url.split('?')
  const url = groups[0].endsWith('/') ? groups[0].slice(0, -1) : groups[0]
  const sections = url.split('/')

  // Lista de palabras clave a buscar en la URL
  const forbiddenWords = [
    'itsgonnafail',
    '.php',
    'wp-',
    'admin',
    'sites',
    'alfa',
    // 'api',
  ]

  // Comprobar si la URL contiene alguna de las palabras prohibidas
  const containsForbiddenWord = forbiddenWords.some((w) => url.includes(w))

  const validSections = sections.filter((s) => s !== '')

  const hasUpperCaseOrNumber =
    validSections.length > 0 ? /[A-Z0-9]/.test(validSections[0]) : false

  if (
    (containsForbiddenWord ||
      hasUpperCaseOrNumber ||
      ['ru'].includes(userCountry.toLowerCase())) &&
    !(sections.length >= 2 && sections[1] === '404')
  ) {
    res.writeHead(301, { Location: '/404' })

    res.end()
  } else {
    next()
  }
}
