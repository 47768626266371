export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  country: {
    alpha_2_code: null,
    alpha_3_code: null,
    dial_code: null,
    flag: null,
    flag_code: null,
    health_information: null,
    import_export_information: null,
    lgbt_information: null,
    lgbt_travel_rank: null,
    name: null,
    brief_description: null,
    population: null,
    slug: null,
    travel_safety: null,
    status: null,
  },
  general_information: {},
  export_regulations: {},
  import_regulations: {},
  health_regulations: {},
  temperatures: [],
  rainfalls: [],
  lgbt_rights: {},
  budget_travel: [],
  visa: {
    type: null,
    label: null,
  },
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state.country = {
      alpha_2_code: null,
      alpha_3_code: null,
      dial_code: null,
      flag: null,
      flag_code: null,
      health_information: null,
      import_export_information: null,
      lgbt_information: null,
      lgbt_travel_rank: null,
      name: null,
      brief_description: null,
      population: null,
      slug: null,
      travel_safety: null,
      status: null,
    }
    state.general_information = {}
    state.export_regulations = {}
    state.import_regulations = {}
    state.health_regulations = {}
    state.temperatures = []
    state.rainfalls = []
    state.lgbt_rights = {}
    state.visa = {
      type: '',
      label: '',
    }
  },
  SET_COUNTRY: (state, data) => {
    state.country = data || {}
  },
  SET_GENERAL_INFORMATION: (state, data) => {
    state.general_information = data || {}
  },
  SET_TEMPERATURES: (state, data) => {
    state.temperatures = data || []
  },
  SET_RAINFALLS: (state, data) => {
    state.rainfalls = data || []
  },
  SET_EXPORT_REGULATIONS: (state, data) => {
    state.export_regulations = data || {}
  },
  SET_IMPORT_REGULATIONS: (state, data) => {
    state.import_regulations = data || {}
  },
  SET_HEALTH_REGULATIONS: (state, data) => {
    state.health_regulations = data || {}
  },
  SET_LGBT_RIGHTS: (state, data) => {
    state.lgbt_rights = data || {}
  },
  SET_BUDGET_TRAVEL: (state, data) => {
    state.budget_travel = data || []
  },
  SET_VISA: (state, data) => {
    state.visa = data
  },
}

export const getters = {}

export const actions = {
  async getData({ commit }, { country }) {
    try {
      const url = `/api/countries/${country}/information`

      const { status, data } = await this.$axios.get(url)

      if (status === 'success') {
        commit('SET_COUNTRY', data.country)
        commit('SET_GENERAL_INFORMATION', data.general_information)
        commit('SET_TEMPERATURES', data.temperatures)
        commit('SET_RAINFALLS', data.rainfalls)
        commit('SET_EXPORT_REGULATIONS', data.export_regulations)
        commit('SET_IMPORT_REGULATIONS', data.import_regulations)
        commit('SET_HEALTH_REGULATIONS', data.health_regulations)
        commit('SET_LGBT_RIGHTS', data.lgbt_rights)
        commit('SET_BUDGET_TRAVEL', data.budget_travel)

        try {
          const url = `/api/country/required-visa/${process.env.APP_COUNTRY}/${data.country.alpha_3_code}`
          const { visa, status } = await this.$axios.get(url)

          if (status === 'success') {
            commit('SET_VISA', visa)
          }
        } catch (error) {}
      } else {
        commit('SET_DEFAULT', false)
      }
    } catch (error) {
      commit('SET_DEFAULT', false)
    }
  },
  setDefault({ commit }, data) {
    commit('SET_DEFAULT', false)
  },
}
