import Cookies from 'js-cookie'
import { encrypt, decrypt } from '@/utils/crypto'

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  summary: {
    extras: [],
    insurances: [],
    rooms: [],
    flights: [],
    experiences: [],
    packages: [],
  },
  products: {
    extras: [],
    insurances: [],
    rooms: [],
    flights: [],
    experiences: [],
    packages: [],
  },
  loaded: false,
})

export const getters = {
  qty(state) {
    let total = 0

    for (const [, items] of Object.entries(state.summary)) {
      total += items.length
    }

    return total
  },
}

export const mutations = {
  SET_DEFAULT: (state) => {
    state.summary = {
      extras: [],
      insurances: [],
      rooms: [],
      flights: [],
      experiences: [],
      packages: [],
    }

    state.products = {
      extras: [],
      insurances: [],
      rooms: [],
      flights: [],
      experiences: [],
      packages: [],
    }

    state.loaded = false
  },
  SET_SUMMARY: (state, data) => {
    state.summary = data
  },
  ADD_PRODUCTS: (state, { type, items }) => {
    items.forEach((item) => {
      item.promotions.map((promotion) => {
        promotion.amount = (parseFloat(item.price) * promotion.value) / 100
      })
    })

    state.products[type] = items
    state.loaded = true
  },
  SET_TICKETS: (state, { type, index, data }) => {
    const product = state.summary[`${type}s`][index]

    if (product.uuid === data.uuid) {
      state.summary[`${type}s`][index].qty = data.tickets
      state.products[`${type}s`][index].qty = data.tickets

      const serviceFees = (
        state.products[`${type}s`][index].discount_penalizations || []
      ).find((t) => t.type === 'service_fees')

      if (serviceFees) {
        const departureDate =
          state.products[`${type}s`][index]._shopping_cart.departure_date

        serviceFees.amount =
          (departureDate.service_fee_percent / 100) *
          state.products[`${type}s`][index].original_price *
          data.tickets *
          state.products[`${type}s`][index].unit
      }
    }
  },
  SET_OPEN_DATE: (state, { type, uuid }) => {
    const key = state.summary[`${type}s`].findIndex((element) => {
      return element.uuid === uuid
    })

    if (key !== -1) {
      state.summary[`${type}s`][key].open = true
      state.summary[`${type}s`][key].date = null
      state.products[`${type}s`][key].open = true
      state.products[`${type}s`][key].date = null
    }
  },
  SET_WARNING_FOR_ADD_TICKETS: (state, { type, index }) => {
    state.products[`${type}s`][index]._shopping_cart.warning = true
  },
  ADD_PRODUCT_TO_SUMMARY: (state, { type, item }) => {
    let data = {}
    let exQty = 0
    let index = 0
    let element = {}
    let exFound = false

    switch (type) {
      case 'package':
      case 'experience':
        exQty = state.summary[`${type}s`].length

        if (exQty) {
          do {
            element = state.summary[`${type}s`][index]

            if (
              element.uuid === item.uuid &&
              element.departure_date.date === item.departure_date.date &&
              element.departure_date.code === item.departure_date.code
            ) {
              exFound = true
              data = {}
            } else {
              for (const [key, value] of Object.entries(item)) {
                data[key] = value
              }

              index++
            }
          } while (index < exQty && !exFound)
        } else {
          for (const [key, value] of Object.entries(item)) {
            data[key] = value
          }
        }
        break
      case 'extra':
        data = {
          group: { uuid: null },
          uuid: item.uuid,
          qty: item.qty,
          unit: item.unit,
          is_new: item.is_new,
          parent: { index: 0, uuid: item.parent.uuid },
          promotions: [],
          sale_price: item.sale_price,
        }
        break
      case 'insurance':
      case 'room':
        data = {
          uuid: item.uuid,
          qty: item.qty,
          unit: item.unit,
          group: { uuid: item.group.uuid },
          parent: item.parent,
        }
        break
      // case 'flight':
      //   data = {
      //     uuid: item.uuid,
      //     qty: item.qty,
      //     unit: item.unit,
      //     open: item.open,
      //     date: item.date,
      //     availability_uuid: item.availability_uuid,
      //     availability_id: item.availability_id,
      //     parent: item.parent,
      //   }
      //   break
      default:
        break
    }

    if (Object.entries(data).length !== 0) {
      state.summary[`${type}s`].push(data)
    }
  },
  DELETE_PRODUCT_TO_SUMMARY: (state, { type, index, uuid }) => {
    if (state.summary[`${type}s`][index].uuid === uuid) {
      state.summary[`${type}s`].splice(index, 1)
      state.products[`${type}s`].splice(index, 1)
    }
  },
  ENCRYPT_SUMMARY: (state) => {
    Cookies.set('shopping-cart-v1', encrypt(state.summary))
  },
  ADD_COUPON: (state, { products, coupons }) => {
    products.forEach((uuid) => {
      for (const [, items] of Object.entries(state.products)) {
        const item = items.find((t) => t.uuid === uuid)

        if (item) {
          coupons.map((coupon) => {
            coupon.amount = (parseFloat(item.price) * coupon.value) / 100
          })

          item.coupons = coupons
        }
      }
    })
  },
}

export const actions = {
  setDefault({ commit }) {
    commit('SET_DEFAULT')
    commit('ENCRYPT_SUMMARY')
  },
  async getData({ commit, state }) {
    try {
      const { data, status } = await this.$axios.post(
        `/api/v1.5/shopping-cart`,
        state.summary
      )
      if (status === 'success') {
        for (const [type, items] of Object.entries(data)) {
          let itemsToDelete = []
          items.forEach((element, index) => {
            if (element._shopping_cart.to_delete) {
              itemsToDelete.push({
                type: type.slice(0, -1),
                index,
                uuid: element.uuid,
              })
            }
          })

          itemsToDelete.sort((a, b) => b.index - a.index)
          // son elementos de viator
          itemsToDelete.forEach((element) => {
            commit('DELETE_PRODUCT_TO_SUMMARY', {
              type: element.type,
              index: element.index,
              uuid: element.uuid,
            })
          })

          const results = items.filter(
            (element) => !element._shopping_cart.to_delete
          )
          commit('ADD_PRODUCTS', { type, items: results })

          // Todos los warning son experiencias que se pasaron a fecha abierta
          // por que la fecha ya no estaba disponible o por que ya no estaban disponibles todos los tickets que queria el user
          const warnings = []
          const warningsOpenDates = []
          results.forEach((element, index) => {
            if (element._shopping_cart.warning)
              warnings.push({ index, data: element })
          })
          itemsToDelete = []
          warnings.forEach((element) => {
            const key = state.products[type].findIndex(
              (experience) =>
                experience.uuid === element.data.uuid &&
                !experience._shopping_cart.warning
            )
            // una experiencia en fecha abierta no puede tener warning por que no tiene limite de tickets
            if (key !== -1) {
              itemsToDelete.push({
                index: element.index,
                uuid: element.data.uuid,
              })

              commit('SET_TICKETS', {
                type: type.slice(0, -1),
                index: key,
                data: {
                  tickets: state.products[type][key].qty + element.data.qty,
                  uuid: state.products[type][key].uuid,
                },
              })

              commit('SET_WARNING_FOR_ADD_TICKETS', {
                type: type.slice(0, -1),
                index: key,
              })
            } else {
              warningsOpenDates.push(element.data)
            }
          })
          itemsToDelete.sort((a, b) => b.index - a.index)
          itemsToDelete.forEach((element) => {
            commit('DELETE_PRODUCT_TO_SUMMARY', {
              type: 'experience',
              index: element.index,
              uuid: element.uuid,
            })
          })

          itemsToDelete = []
          const uniqueKeys = []
          warningsOpenDates.forEach((element, index) => {
            warningsOpenDates.forEach((item, indice) => {
              if (
                element.uuid === item.uuid &&
                index !== indice &&
                !uniqueKeys.some((t) => t === indice)
              ) {
                commit('SET_TICKETS', {
                  type: type.slice(0, -1),
                  index,
                  data: {
                    tickets: element.qty + item.qty,
                    uuid: element.uuid,
                  },
                })
                uniqueKeys.push(index)
                itemsToDelete.push({
                  index: indice,
                  uuid: item.uuid,
                })
              }
            })
          })
          itemsToDelete.sort((a, b) => b.index - a.index)
          itemsToDelete.forEach((element) => {
            commit('DELETE_PRODUCT_TO_SUMMARY', {
              type: type.slice(0, -1),
              index: element.index,
              uuid: element.uuid,
            })
          })

          const openDates = state.products[type].filter(
            (element) => element._shopping_cart.warning === true
          )

          openDates.forEach((element) => {
            commit('SET_OPEN_DATE', {
              type: type.slice(0, -1),
              uuid: element.uuid,
            })
          })
        }
      }
      commit('ENCRYPT_SUMMARY')
    } catch (error) {}
  },
  addProduct({ commit }, data) {
    commit('ADD_PRODUCT_TO_SUMMARY', data)
    commit('ENCRYPT_SUMMARY')
  },
  deleteProduct({ commit }, data) {
    commit('DELETE_PRODUCT_TO_SUMMARY', data)
    commit('ENCRYPT_SUMMARY')
  },
  init({ commit }) {
    const cookie = Cookies.get('shopping-cart-v1')

    if (cookie) {
      const data = decrypt(cookie)
      commit('SET_SUMMARY', data)
    }
  },
  setTickets({ commit }, data) {
    commit('SET_TICKETS', data)
    commit('ENCRYPT_SUMMARY')
  },
  setOpenDate({ commit }, data) {
    commit('SET_OPEN_DATE', data)
    commit('ENCRYPT_SUMMARY')
  },

  async getFastCheckout({ commit, state }) {
    try {
      const { data, status } = await this.$axios.post(
        `/api/v1.5/shopping-cart`,
        state.summary
      )
      if (status === 'success') {
        for (const [type, items] of Object.entries(data)) {
          commit('ADD_PRODUCTS', { type, items })
        }
      }
    } catch (error) {}
  },

  setFastCheckout({ commit }, element) {
    commit('SET_DEFAULT')
    commit('ADD_PRODUCT_TO_SUMMARY', element)
  },

  addExtra({ commit }, element) {
    commit('ADD_PRODUCT_TO_SUMMARY', element)
  },

  addCoupon({ commit }, data) {
    commit('ADD_COUPON', data)
  },
}
