// eslint-disable-next-line import/no-relative-parent-imports
import { SEARCH_TYPE_ROUTER } from '../../utils/static-router-alias'

const axios = require('axios')

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default async (req, res, next) => {
  const language = process.env.APP_LOCALE.toLowerCase().split('_')[0]

  const groups = req.url.split('?')
  const url = groups[0].endsWith('/') ? groups[0].slice(0, -1) : groups[0]
  const sections = url.split('/')
  sections.shift()

  const baseType = SEARCH_TYPE_ROUTER.base[sections[0]]

  const keys = Object.keys(SEARCH_TYPE_ROUTER.groups)
  const validsTabs = Object.values(
    typeof SEARCH_TYPE_ROUTER.tabs[language] !== 'undefined'
      ? SEARCH_TYPE_ROUTER.tabs[language]
      : {}
  )

  if (/\.(png|jpg|jpeg|svg)$/.test(url)) {
    next()
  } else if (keys.includes(baseType)) {
    const isPageWithDays =
      (SEARCH_TYPE_ROUTER.groups.continent.includes(sections[0]) ||
        SEARCH_TYPE_ROUTER.groups.country.includes(sections[0])) &&
      sections.length === 4 &&
      sections[2] === SEARCH_TYPE_ROUTER.base.tours

    if (
      (SEARCH_TYPE_ROUTER.groups[baseType].includes(sections[0]) &&
        validsTabs.includes(sections.length === 3 ? sections[2] : null)) ||
      isPageWithDays
    ) {
      // Initiate axios
      const instance = axios.create({
        baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-localization': process.env.APP_LOCALE,
          'X-currency': process.env.APP_CURRENCY,
        },
      })

      let newLocation = null

      try {
        const {
          data: { data, status },
        } = await instance.post('/api/settings/validate-slug', {
          type: baseType,
          slug: sections[1] || '',
          url,
        })

        if (status === 'success') {
          if (
            process.env.APP_URL !== process.env.APP_URL_EN_US &&
            data.current.es !== sections[1]
          ) {
            sections[1] = data.current.es
            newLocation = `/${sections[0]}/${sections[1]}/${sections[2]}`
            if (sections.length === 4) newLocation += `/${sections[3]}`
            res.writeHead(301, { Location: newLocation })
            res.end()
          }
        }
      } catch (error) {}

      if (sections.length === 4) {
        const regex = new RegExp('^\\d?\\d')
        const days = sections[3].match(regex)

        if (days === null) {
          newLocation = `/${sections[0]}/${sections[1]}/${sections[2]}`
          res.writeHead(301, { Location: newLocation })
          res.end()
        }
      }

      next()
    } else {
      let tab = SEARCH_TYPE_ROUTER.tabs[language].tours
      if (baseType === 'country' && url.endsWith('/information')) {
        tab = SEARCH_TYPE_ROUTER.tabs[language].trips
      }

      const newType = SEARCH_TYPE_ROUTER.alias[language][baseType]
      let permalink = sections[1]
      let newLocation = null

      // Initiate axios
      const instance = axios.create({
        baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-localization': process.env.APP_LOCALE,
          'X-currency': process.env.APP_CURRENCY,
        },
      })

      try {
        const {
          data: { data, status },
        } = await instance.post('/api/settings/validate-slug', {
          type: baseType,
          slug: permalink || '',
          url,
        })

        if (status === 'success') {
          permalink = data.current.default
        }
      } catch (error) {}

      newLocation = `/${newType}/${permalink}/${tab}`

      if (newLocation !== url) {
        if (groups.length === 2) {
          newLocation += '?' + groups[1]
        }

        res.writeHead(301, { Location: newLocation })

        res.end()
      } else {
        next()
      }
    }
  } else {
    next()
  }
}
