const SEARCH_TYPE = {
  alias: {
    es: {
      continent: 'continente',
      country: 'pais',
      city: 'ciudad',
      category: 'categoria',
    },
    en: {
      continent: 'continent',
      country: 'country',
      city: 'city',
      category: 'category',
    },
  },
  tabs: {
    es: {
      trips: 'viajes',
      tours: 'tours',
    },
    en: {
      trips: 'trips',
      tours: 'tours',
    },
  },
  base: {
    // type
    continent: 'continent',
    country: 'country',
    city: 'city',
    category: 'category',

    continente: 'continent',
    pais: 'country',
    ciudad: 'city',
    categoria: 'category',

    // tabs
    trips: 'trips',
    tours: 'tours',
    flights: 'flights',

    viajes: 'trips',
    vuelos: 'flights',
  },
  groups: {
    continent: ['continent', 'continente'],
    country: ['country', 'pais'],
    city: ['city', 'ciudad'],
    category: ['category', 'categoria'],
  },
  days: {
    es: 'dias',
    en: 'days',
  },
}

const PRODUCT_PERMALINK = {
  alias: {
    es: {
      experience: 'tours',
      package: 'paquete',
    },
    en: {
      experience: 'tours',
      package: 'package',
    },
  },
  base: {
    // type
    experience: 'experience',
    package: 'package',

    tours: 'experience',
    paquete: 'package',
  },
  groups: {
    experience: ['experience', 'tours'],
    package: ['package', 'paquete'],
  },
  abtesting: ['ppcv1'],
}

const LANDING_PERMALINK = {
  abtesting: ['gnrl1', 'gnrl2', 'exp1'],
}

const FLIGHTS = {
  alias: {
    es: {
      flights: 'vuelos',
    },
    en: {
      flights: 'flights',
    },
  },
}

export default {
  search_type: SEARCH_TYPE,
  product_permalink: PRODUCT_PERMALINK,
  landing_permalink: LANDING_PERMALINK,
  flights: FLIGHTS,
}

export const SEARCH_TYPE_ROUTER = SEARCH_TYPE
export const PRODUCT_PERMALINK_ROUTER = PRODUCT_PERMALINK
export const LANDING_PERMALINK_ROUTER = LANDING_PERMALINK
export const FLIGHTS_ROUTER = FLIGHTS
