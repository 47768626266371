import { SEARCH_TYPE_ROUTER } from '@/utils/static-router-alias'
/** revisar `components/search/mixin/price.js` si hay cambios en el min y max */
const price = { min: 50, max: 15000 }
const duration = { min: 1, max: 45 }
const age = { min: 1, max: 99 }

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  form: {
    filters: {
      date: [],
      price: [],
      duration: [],
      age: [],
      category: [],
    },
    limit: 30,
    page: 1,
    total: 0,
    search: null,
    sort: { term: 'price_promo', direction: 'asc' },
  },
  values: { price, duration, age },
  header: {
    name: '',
    description: '',
    permalink: { default: null, es: null, en: null },
    media: {
      src: null,
      srcset: null,
    },
    durations: [],
    reviews: [],
  },
  items: [],
  total: 0,
  breadcrumbs: [],
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state.form = {
      filters: {
        date: [],
        price: [],
        duration: [],
        age: [],
        category: [],
      },
      limit: 30,
      page: 1,
      total: 0,
      search: null,
      sort: { term: 'price_promo', direction: 'asc' },
    }
    state.header = {
      name: '',
      description: '',
      permalink: { default: null, es: null, en: null },
      media: { src: null, srcset: null },
      durations: [],
      reviews: [],
    }
    state.items = []
    state.total = 0
    state.breadcrumbs = []
  },
  SET_HEADER: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.header, key)) {
        if ((key === 'media' && !!value.src) || key !== 'media') {
          state.header[key] =
            key === 'description' && (value || '').split(' ').length < 2
              ? ''
              : value
        }
      }
    }
  },
  SET_FORM: (state, data) => {
    if (Reflect.has(data, 'filters')) {
      for (const [key, value] of Object.entries(data.filters)) {
        /** solo se agregan los valores a filtrar que corresponden */
        if (Reflect.has(state.form.filters, key)) {
          state.form.filters[key] = value
        } else if (Reflect.has(state.form, key)) {
          state.form[key] = parseFloat(value)
        }
      }
    }

    if (Reflect.has(data, 'sort')) {
      const sort = Array.isArray(data.sort)
        ? data.sort[0].split('__')
        : (data.sort || '').split('__')

      if (sort.length === 2) {
        state.form.sort = { term: sort[0], direction: sort[1] }
      }
    }

    if (!!data.search && data.search.trim().length > 0) {
      state.form.search = data.search
    } else if (typeof data.search !== 'undefined') {
      state.form.search = ''
    }
    /** @todo ir aumentando funcionalidades para paginado y sort de listado */
  },
  SET_ITEMS: (state, data) => {
    const list = []
    data.forEach((item) => {
      list.push({
        uuid: item.uuid,
        type: item.type ? item.type : 'experience',
        title: item.name,
        description: item.headline,
        days: item.duration,
        categories: item.categories || [],
        total_amount: parseFloat(item.sales_plan.total_amount),
        reviews: {
          count: parseFloat(item.reviews.count),
          value: parseFloat(item.reviews.value),
        },
        starting_point: item.starting_point,
        permalink: item.permalink,
        promotions: item.promotions,
        country: item.country,
        media: {
          type: 'image',
          data: {
            url: item.cover.url,
            srcset: item.cover.srcset,
            uuid: item.cover.uuid,
          },
        },
        is_lad: item.is_lad,
        includes: item.includes,
        languages: item.languages,
      })
    })

    state.items = list
  },
  UPDATE_FORM_FILTER: (state, { filter, data }) => {
    if (Reflect.has(state.form.filters, filter)) {
      state.form.filters[filter] = data
    }
  },
  SET_PAGINATE: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.form, key) && !isNaN(value)) {
        state.form[key] = parseFloat(value)
      }
    }
  },
  SET_BREADCRUMBS: (state, data) => {
    state.breadcrumbs = data
  },
}

export const getters = {}

export const actions = {
  setCover({ commit }, data) {
    commit('SET_HEADER', data)
  },
  setForm({ commit }, data) {
    commit('SET_FORM', data)
  },
  updateFormFilter({ commit }, data) {
    commit('UPDATE_FORM_FILTER', data)
  },
  updatePaginate({ commit }, data) {
    commit('SET_PAGINATE', data)
  },
  updateBreadcrumbs({ commit }, data) {
    commit('SET_BREADCRUMBS', data)
  },
  async getExperiences({ commit }, search) {
    try {
      const { data, status } = await this.$axios.post(
        `/api/search/${search.type}/${search.value}`,
        search.form
      )

      if (status === 'success') {
        if (SEARCH_TYPE_ROUTER.groups.category.includes(data.type)) {
          const slug = data.header.permalink.en.replace('-', '_')
          data.header.media = {
            srcset: require(`@/assets/images/categories/${slug}.jpg`),
            src: require(`@/assets/images/categories/${slug}.jpg`),
          }

          commit('SET_HEADER', data.header)
        }

        commit('SET_ITEMS', data.items)
        commit('SET_PAGINATE', {
          page: data.current_page,
          total: data.total,
          limit: data.limit,
        })
      }
    } catch (error) {
      commit('SET_HEADER', {})
      commit('SET_ITEMS', [])
      commit('SET_PAGINATE', {})
    }
  },
  async getHeader({ commit }, { type, value }) {
    commit('SET_ITEMS', [])

    try {
      const { data, status } = await this.$axios.get(
        `/api/search/${type}/${value}`
      )

      if (status === 'success') {
        commit('SET_HEADER', data.header)
        commit('SET_BREADCRUMBS', data.breadcrumbs)
      }
    } catch (error) {}
  },
}
