import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bc7d4d28 = () => interopDefault(import('../pages/401.vue' /* webpackChunkName: "pages/401" */))
const _bc28c022 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _0f589bb7 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _287e34ba = () => interopDefault(import('../pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _41e7f38b = () => interopDefault(import('../pages/approved-partner.vue' /* webpackChunkName: "pages/approved-partner" */))
const _6e5533d3 = () => interopDefault(import('../pages/buen-fin.vue' /* webpackChunkName: "pages/buen-fin" */))
const _c31cd8f8 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _0a25e27c = () => interopDefault(import('../pages/customer-benefits.vue' /* webpackChunkName: "pages/customer-benefits" */))
const _ed4f716a = () => interopDefault(import('../pages/hotsale.vue' /* webpackChunkName: "pages/hotsale" */))
const _043c014a = () => interopDefault(import('../pages/localflex.vue' /* webpackChunkName: "pages/localflex" */))
const _b65394ae = () => interopDefault(import('../pages/outlet/index.vue' /* webpackChunkName: "pages/outlet/index" */))
const _7d7f8ea0 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4caec87e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _49b469dc = () => interopDefault(import('../pages/shopping-cart.vue' /* webpackChunkName: "pages/shopping-cart" */))
const _615e3882 = () => interopDefault(import('../pages/social-auth.vue' /* webpackChunkName: "pages/social-auth" */))
const _29582490 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _7e95da91 = () => interopDefault(import('../pages/visas-and-restrictions.vue' /* webpackChunkName: "pages/visas-and-restrictions" */))
const _adf2cb54 = () => interopDefault(import('../pages/wheel-of-fortune.vue' /* webpackChunkName: "pages/wheel-of-fortune" */))
const _b502ba74 = () => interopDefault(import('../pages/why-choose-us.vue' /* webpackChunkName: "pages/why-choose-us" */))
const _059b7921 = () => interopDefault(import('../pages/c/mountains-in-mexico.vue' /* webpackChunkName: "pages/c/mountains-in-mexico" */))
const _65f86b4c = () => interopDefault(import('../pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara.vue' /* webpackChunkName: "pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara" */))
const _5f19514d = () => interopDefault(import('../pages/landingz/caza-de-auroras-grupo-en-espanol.vue' /* webpackChunkName: "pages/landingz/caza-de-auroras-grupo-en-espanol" */))
const _51bd6205 = () => interopDefault(import('../pages/f/components/availability/index.vue' /* webpackChunkName: "pages/f/components/availability/index" */))
const _2e745348 = () => interopDefault(import('../pages/f/components/availability/components/card.vue' /* webpackChunkName: "pages/f/components/availability/components/card" */))
const _7e63cfb7 = () => interopDefault(import('../pages/user/profile/_tab.vue' /* webpackChunkName: "pages/user/profile/_tab" */))
const _46f8e7ea = () => interopDefault(import('../pages/user/reservation/_code/edit.vue' /* webpackChunkName: "pages/user/reservation/_code/edit" */))
const _1a8381b9 = () => interopDefault(import('../pages/public/reservation/_code/_tab.vue' /* webpackChunkName: "pages/public/reservation/_code/_tab" */))
const _a6087252 = () => interopDefault(import('../pages/user/reservation/_code/_tab.vue' /* webpackChunkName: "pages/user/reservation/_code/_tab" */))
const _2beb774a = () => interopDefault(import('../pages/announcement/_slug.vue' /* webpackChunkName: "pages/announcement/_slug" */))
const _0662a4e4 = () => interopDefault(import('../pages/f/_permalink.vue' /* webpackChunkName: "pages/f/_permalink" */))
const _801b2d68 = () => interopDefault(import('../pages/h/_permalink.vue' /* webpackChunkName: "pages/h/_permalink" */))
const _0eb36a06 = () => interopDefault(import('../pages/invitation/_referral_code.vue' /* webpackChunkName: "pages/invitation/_referral_code" */))
const _4639e0c8 = () => interopDefault(import('../pages/l/_permalink.vue' /* webpackChunkName: "pages/l/_permalink" */))
const _64991186 = () => interopDefault(import('../pages/magic-link/_token.vue' /* webpackChunkName: "pages/magic-link/_token" */))
const _d3faba70 = () => interopDefault(import('../pages/outlet/_test.vue' /* webpackChunkName: "pages/outlet/_test" */))
const _49e0d2e2 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _969abec6 = () => interopDefault(import('../pages/permalink/_slug.vue' /* webpackChunkName: "pages/permalink/_slug" */))
const _33cde8df = () => interopDefault(import('../pages/landing/_permalink/_test.vue' /* webpackChunkName: "pages/landing/_permalink/_test" */))
const _0d8dd26e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b4676d6 = () => interopDefault(import('../pages/_influencer/aventura-rio-nilo.vue' /* webpackChunkName: "pages/_influencer/aventura-rio-nilo" */))
const _02314172 = () => interopDefault(import('../pages/_influencer/explorando-turquia-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/explorando-turquia-con-vuelo" */))
const _808c2804 = () => interopDefault(import('../pages/_influencer/lo-mejor-peru.vue' /* webpackChunkName: "pages/_influencer/lo-mejor-peru" */))
const _0dc950c1 = () => interopDefault(import('../pages/_influencer/noches-auroras-boreales-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/noches-auroras-boreales-con-vuelo" */))
const _294637ab = () => interopDefault(import('../pages/_influencer/salkantay-trek.vue' /* webpackChunkName: "pages/_influencer/salkantay-trek" */))
const _a197eec8 = () => interopDefault(import('../pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia.vue' /* webpackChunkName: "pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia" */))
const _1c3f9954 = () => interopDefault(import('../components/pages/checkout-layaway/index.vue' /* webpackChunkName: "" */))
const _7b481210 = () => interopDefault(import('../components/pages/experience-package/ppcv1.vue' /* webpackChunkName: "" */))
const _0086f1ac = () => interopDefault(import('../components/pages/experience-package/index.vue' /* webpackChunkName: "" */))
const _f96f4790 = () => interopDefault(import('../components/pages/phocuswright/index.vue' /* webpackChunkName: "" */))
const _4a3d387c = () => interopDefault(import('../components/pages/outlet-viaja-vuela/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/401",
    component: _bc7d4d28,
    name: "401"
  }, {
    path: "/404",
    component: _bc28c022,
    name: "404"
  }, {
    path: "/about-us",
    component: _0f589bb7,
    name: "about-us"
  }, {
    path: "/announcement",
    component: _287e34ba,
    name: "announcement"
  }, {
    path: "/approved-partner",
    component: _41e7f38b,
    name: "approved-partner"
  }, {
    path: "/buen-fin",
    component: _6e5533d3,
    name: "buen-fin"
  }, {
    path: "/contact-us",
    component: _c31cd8f8,
    name: "contact-us"
  }, {
    path: "/customer-benefits",
    component: _0a25e27c,
    name: "customer-benefits"
  }, {
    path: "/hotsale",
    component: _ed4f716a,
    name: "hotsale"
  }, {
    path: "/localflex",
    component: _043c014a,
    name: "localflex"
  }, {
    path: "/outlet",
    component: _b65394ae,
    name: "outlet"
  }, {
    path: "/privacy-policy",
    component: _7d7f8ea0,
    name: "privacy-policy"
  }, {
    path: "/search",
    component: _4caec87e,
    name: "search"
  }, {
    path: "/shopping-cart",
    component: _49b469dc,
    name: "shopping-cart"
  }, {
    path: "/social-auth",
    component: _615e3882,
    name: "social-auth"
  }, {
    path: "/terms-and-conditions",
    component: _29582490,
    name: "terms-and-conditions"
  }, {
    path: "/visas-and-restrictions",
    component: _7e95da91,
    name: "visas-and-restrictions"
  }, {
    path: "/wheel-of-fortune",
    component: _adf2cb54,
    name: "wheel-of-fortune"
  }, {
    path: "/why-choose-us",
    component: _b502ba74,
    name: "why-choose-us"
  }, {
    path: "/c/mountains-in-mexico",
    component: _059b7921,
    name: "c-mountains-in-mexico"
  }, {
    path: "/landing/tours-en-vancouver-con-vuelos-desde-guadalajara",
    component: _65f86b4c,
    name: "landing-tours-en-vancouver-con-vuelos-desde-guadalajara"
  }, {
    path: "/landingz/caza-de-auroras-grupo-en-espanol",
    component: _5f19514d,
    name: "landingz-caza-de-auroras-grupo-en-espanol"
  }, {
    path: "/f/components/availability",
    component: _51bd6205,
    name: "f-components-availability"
  }, {
    path: "/f/components/availability/components/card",
    component: _2e745348,
    name: "f-components-availability-components-card"
  }, {
    path: "/user/profile/:tab?",
    component: _7e63cfb7,
    name: "user-profile-tab"
  }, {
    path: "/user/reservation/:code?/edit",
    component: _46f8e7ea,
    name: "user-reservation-code-edit"
  }, {
    path: "/public/reservation/:code?/:tab?",
    component: _1a8381b9,
    name: "public-reservation-code-tab"
  }, {
    path: "/user/reservation/:code?/:tab?",
    component: _a6087252,
    name: "user-reservation-code-tab"
  }, {
    path: "/announcement/:slug",
    component: _2beb774a,
    name: "announcement-slug"
  }, {
    path: "/f/:permalink?",
    component: _0662a4e4,
    name: "f-permalink"
  }, {
    path: "/h/:permalink?",
    component: _801b2d68,
    name: "h-permalink"
  }, {
    path: "/invitation/:referral_code?",
    component: _0eb36a06,
    name: "invitation-referral_code"
  }, {
    path: "/l/:permalink?",
    component: _4639e0c8,
    name: "l-permalink"
  }, {
    path: "/magic-link/:token?",
    component: _64991186,
    name: "magic-link-token"
  }, {
    path: "/outlet/:test",
    component: _d3faba70,
    name: "outlet-test"
  }, {
    path: "/p/:slug?",
    component: _49e0d2e2,
    name: "p-slug"
  }, {
    path: "/permalink/:slug?",
    component: _969abec6,
    name: "permalink-slug"
  }, {
    path: "/landing/:permalink?/:test?",
    component: _33cde8df,
    name: "landing-permalink-test"
  }, {
    path: "/",
    component: _0d8dd26e,
    name: "index"
  }, {
    path: "/:influencer/aventura-rio-nilo",
    component: _4b4676d6,
    name: "influencer-aventura-rio-nilo"
  }, {
    path: "/:influencer/explorando-turquia-con-vuelo",
    component: _02314172,
    name: "influencer-explorando-turquia-con-vuelo"
  }, {
    path: "/:influencer/lo-mejor-peru",
    component: _808c2804,
    name: "influencer-lo-mejor-peru"
  }, {
    path: "/:influencer/noches-auroras-boreales-con-vuelo",
    component: _0dc950c1,
    name: "influencer-noches-auroras-boreales-con-vuelo"
  }, {
    path: "/:influencer/salkantay-trek",
    component: _294637ab,
    name: "influencer-salkantay-trek"
  }, {
    path: "/:influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia",
    component: _a197eec8,
    name: "influencer-santorini-naxos-y-atenas-en-una-aventura-por-grecia"
  }, {
    path: "/:type(continent|country|city|category|continente|pais|ciudad|categoria)/:value/:tab(trips|tours|viajes|tours)?/:days?",
    component: _4caec87e,
    name: "search-type"
  }, {
    path: "/:type(checkout|layaway|fast-checkout)",
    component: _1c3f9954,
    name: "checkout-layaway"
  }, {
    path: "/:type(tours|package)/:permalink/:test(ppcv1)",
    component: _7b481210,
    name: "product-permalink-ppcv1"
  }, {
    path: "/:type(tours|package)/:permalink",
    component: _0086f1ac,
    name: "product-permalink"
  }, {
    path: "/:type(general-phocuswright-2022|phochuswright-2022-travel-tech-leaders|phocuswright-2022-invite-only)",
    component: _f96f4790,
    name: "phocuswright"
  }, {
    path: "/expo-viaja-vuela-2023",
    component: _4a3d387c,
    name: "expo-viaja-vuela-2023"
  }, {
    path: "*",
    redirect: "/404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
